import { useEffect } from 'react'
import useTypedSelector from 'interfaces/useTypedSelector'
import { useDispatch } from 'react-redux'
import { setActiveCompanyRelationId } from 'reducers/authReducer'
import { getInLocalStorage, setInLocalStorage } from 'helpers/storage/storage'

const KEY = 'active-company-id'

export const useActiveCompany = () => {
	const dispatch = useDispatch()
	const { userInfo, activeCompanyRelationId, userInfoState } = useTypedSelector(({ auth }) => auth)
	const activeRelation = userInfo?.companyUsers.find(x => x.id === activeCompanyRelationId)
	const valueFromStorage = getInLocalStorage(KEY)

	const setRelation = (id: string) => {
		setInLocalStorage(KEY, id)
		dispatch(setActiveCompanyRelationId(id))
	}

	// If the user has an active list of companies but none is set in the local storage
	// we set the default one to the first on in the array
	useEffect(() => {
		// The user has an active list of relations but no value is set in the store
		const noActiveStorageValue = userInfo?.companyUsers && userInfo.companyUsers.length > 0 && !valueFromStorage
		// The user has an active list of relations and a value set in storage but it is not a part of his current active relations
		// (This can happen if a user's previously active relation has been revoked)
		const noMatchFound =
			userInfo?.companyUsers &&
			valueFromStorage &&
			userInfo.companyUsers.length > 0 &&
			!userInfo.companyUsers.find(x => x.id === valueFromStorage)

		if (noActiveStorageValue || noMatchFound) {
			setRelation(userInfo?.companyUsers[0].id || '')
		} else if (valueFromStorage !== activeCompanyRelationId && valueFromStorage) {
			dispatch(setActiveCompanyRelationId(valueFromStorage))
		}
	}, [userInfo, valueFromStorage])

	return { activeRelation, setRelation, state: userInfoState }
}
