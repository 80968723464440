import User from '@local-types/user/user'
import StorefrontUser from '@local-types/user/storefrontUser'
import { createAction, createReducer } from '@reduxjs/toolkit'
import { AppThunk } from 'store'
import { fetchWithUserAuth, putWithUserAuth } from 'helpers/authHelpers/authHelpers'
import { API_URL } from 'constants/paths/paths'
import { AsyncActionState } from 'interfaces/global/asyncActionState'
import toastMessage from 'components/utils/toasts'

export type AuthState = 'pending' | 'notLoggedIn' | 'loggedIn'

interface SetAuthUser {
	authState: AuthState
	user: User | null
}

interface SetUserInfo {
	userInfo: StorefrontUser | null
}

export const setAuthUser = createAction<SetAuthUser>('set-auth-user')
export const setActiveCompanyRelationId = createAction<string>('set-active-company-relation-id')

export const setUserInfo = createAction<SetUserInfo>('set-user-info')

export const fetchUserInfoPending = createAction('fetch-user-info-pending')
export const fetchUserInfoFulfilled = createAction<StorefrontUser>('fetch-user-info-fulfilled')
export const fetchUserInfoFailed = createAction('fetch-user-info-failed')
export const fetchUserInfo = (): AppThunk => async dispatch => {
	try {
		dispatch(fetchUserInfoPending())
		const res = await fetchWithUserAuth(`${API_URL}/users/me`)
		dispatch(fetchUserInfoFulfilled(res.data as StorefrontUser))
	} catch (err) {
		dispatch(fetchUserInfoFailed())
	}
}

export const updateUserInfoPending = createAction('update-user-info-pending')
export const updateUserInfoFulfilled = createAction<StorefrontUser>('update-user-info-fulfilled')
export const updateUserInfoFailed = createAction('update-user-info-failed')
export const updateUserInfo = (name: string, email: string, phone: string): AppThunk => async dispatch => {
	try {
		dispatch(updateUserInfoPending())
		const res = await putWithUserAuth(`${API_URL}/users/me`, {
			name,
			email,
			phone,
			active: true,
		})
		dispatch(updateUserInfoFulfilled(res.data as StorefrontUser))
		toastMessage({
			type: 'success',
			message: 'Upplýsingarnar þínar hafa verið uppfærðar',
		})
		dispatch(fetchUserInfo())
	} catch (err) {
		dispatch(updateUserInfoFailed())
		toastMessage({
			type: 'error',
			message: 'Eitthvað fór úrskeiðis, tókst ekki að uppfæra þínar upplýsingar',
		})
	}
}

export interface AuthReducer {
	authState: AuthState
	user: User | null
	userInfo: StorefrontUser | null
	userInfoState: AsyncActionState
	activeCompanyRelationId: string | null
	updateUserInfoState: AsyncActionState
}

const initialState: AuthReducer = {
	authState: 'pending',
	user: null,
	userInfo: null,
	userInfoState: 'passive',
	activeCompanyRelationId: null,
	updateUserInfoState: 'passive',
}

const authReducer = createReducer(initialState, builder =>
	builder
		.addCase(setAuthUser, (store, action) => {
			store.authState = action.payload.authState
			store.user = action.payload.user
		})
		.addCase(fetchUserInfoPending, store => {
			store.userInfoState = 'pending'
		})
		.addCase(fetchUserInfoFulfilled, (store, action) => {
			store.authState = 'loggedIn'
			store.userInfoState = 'fulfilled'
			store.userInfo = action.payload
		})
		.addCase(fetchUserInfoFailed, store => {
			store.authState = 'notLoggedIn'
			store.userInfoState = 'failed'
		})
		.addCase(setActiveCompanyRelationId, (store, action) => {
			store.activeCompanyRelationId = action.payload
		})
		.addCase(updateUserInfoPending, store => {
			store.updateUserInfoState = 'pending'
		})
		.addCase(updateUserInfoFulfilled, store => {
			store.updateUserInfoState = 'fulfilled'
		})
		.addCase(updateUserInfoFailed, store => {
			store.updateUserInfoState = 'failed'
		})
		.addCase(setUserInfo, (store, action) => {
			store.userInfo = action.payload.userInfo
		})
)

export default authReducer
