import axios from 'axios'
import { Auth } from 'aws-amplify'
import { getInLocalStorage } from '../storage/storage'
import { INACTIVE_USER_TIME_THRESHOLD } from '../../constants/inactivityTracking/sessionInactivityThreshold'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchWithUserAuth = async (url: string, params?: any) => {
	const session = await Auth.currentSession()
	const config = {
		headers: {
			Authorization: `Bearer ${session ? session.getAccessToken().getJwtToken() : ''}`,
		},
		params,
	}

	return axios(url, config)
}

export const postWithUserAuth = async (
	url: string,
	data: any, // eslint-disable-line
	responseType?: 'json' | 'arraybuffer' | 'blob' | 'document' | 'text' | 'stream' | undefined
) => {
	const session = await Auth.currentSession()
	const config = {
		headers: {
			Authorization: `Bearer ${session ? session.getAccessToken().getJwtToken() : ''}`,
		},
		responseType: responseType || 'json',
	}

	return axios.post(url, data, config)
}

export const putWithUserAuth = async (
	url: string,
	data: any, // eslint-disable-line
	responseType?: 'json' | 'arraybuffer' | 'blob' | 'document' | 'text' | 'stream' | undefined
) => {
	const session = await Auth.currentSession()
	const config = {
		headers: {
			Authorization: `Bearer ${session ? session.getAccessToken().getJwtToken() : ''}`,
		},
		responseType: responseType || 'json',
	}

	return axios.put(url, data, config)
}

export const deleteWithUserAuth = async (
	url: string,
	data: any, // eslint-disable-line
	responseType?: 'json' | 'arraybuffer' | 'blob' | 'document' | 'text' | 'stream' | undefined
) => {
	const session = await Auth.currentSession()
	const config = {
		headers: {
			Authorization: `Bearer ${session ? session.getAccessToken().getJwtToken() : ''}`,
		},
		responseType: responseType || 'json',
		data: data,
	}

	return axios.delete(url, config)
}

export enum SessionValidationResult {
	'Invalid',
	'Valid',
}

export const validateCurrentSession = (): SessionValidationResult => {
	const previousSessionEndDate = getInLocalStorage('previousSessionEndDate')
	if (!previousSessionEndDate) return SessionValidationResult.Valid
	const previousSessionDate = Date.parse(previousSessionEndDate)
	const date = Date.now()
	if (date - previousSessionDate > INACTIVE_USER_TIME_THRESHOLD) {
		return SessionValidationResult.Invalid
	}
	return SessionValidationResult.Valid
}
